const infoBubbleConfig = {
  deforestationKpisIndigenous: 'deforestation_kpis_indigenous_territories',
  kpisProtectedAreas: 'deforestation_kpis_protected_areas',
  bannerHistoricSite: 'historic_site_characteristics',
  bannerListAttributes: 'fc_list_attributes',
  saLossOfForests: 'sensitive_areas_loss_annual',
  saHistoricAreasLoss: 'sensitive_areas_historic_loss',
  regionsSaAnnual: 'regions_sa_annual',
  regionsSaBiomes: 'regions_sa_biomes_calc',
  regionsProdesAnnual: 'regions_prodes_annual',
  regionsSoyAnnual: 'regions_soy_annual',
  regionsSoyCalc: 'regions_soy_calculations',
  regionsSeaAnnual: 'regions_sea_annual',
  regionsSeaLcCalc: 'regions_sea_land_cover_calc',
  regionsIdnLcCalc: 'regions_idn_land_cover_calc',
  regionsIdnLegal: 'regions_idn_legal_class_calc',
  regionsIdnForestM: 'regions_idn_forest_moratorium_calc',
  forestsVsPlantation: 'forests_vs_plantations_loss',
  plantationTypes: 'plantation_types',
  carbonEmissions: 'carbon_emissions_annual',
  trendsDeforestation: 'trends_in_deforestation',
  millPriorityHistory: 'mill_priority-history',
  crPalmRisk: 'cr_palm_risk_analysis',
  policyPA: 'policy_protected_areas',
  policyNaturalH: 'policy_natural_habitat_proxies',
  policyIndigP: 'policy_indigenous_peoples',
  policyFires: 'policy_fires',
  investmentLocations: 'investment_locations_map',
  carbonTCL: 'carbon_from_tree_cover_loss',
  portfolioTCL: 'portfolio_annual_tree_cover_loss',
  lossAllYears: 'loss_all_years',
  threats: 'threats',
  values: 'values',
  threatsAndValues: 'threats_and_values',
  treeCoverLossArgOtbnYearly: 'tree_cover_loss_arg_otbn_yearly',
  analysisOverview: 'deforestation_kpis_analysis_overview',
  landcoverCalc: 'deforestation_kpis_landcover_calc',
  naturalForestCoverage: 'deforestation_kpis_natural_forest_coverage',
  naturalForestLoss: 'deforestation_kpis_natural_forest_loss',
  negligibleRisk: 'deforestation_kpis_negligible_risk',
};

export default infoBubbleConfig;
